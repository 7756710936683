import { useState } from 'react';
import { styled, useTheme } from '@mui/material/styles';
import FileUpload from 'components/files/FileUpload';
import Chat from 'components/chat/Chat';
import AIResult from 'components/result/Result';
import req from 'lib/axios';
import config from 'configs';
import DefaultLoadingModal from 'components/modal/DefaultLoadingModal';
import CustomLoadingModal from 'components/modal/CustomLoadingModal';

const Wrapper = styled('div')(({ theme }) => ({
  width: '100%',
  [theme.breakpoints.down('md')]: {
    height: '100%'
  },
  paddingTop: 80,
  display: 'inline-block',
  padding: 30
}));

const Main = () => {
  const theme = useTheme();

  const [ocrText, setOcrText] = useState([]);
  const [showInputGroup, setShowInputGroup] = useState(false);

  const [backgroundDescription, setBackgroundDescription] = useState(''); // 배경 설명 상태
  const [editingIndex, setEditingIndex] = useState(null);
  const [editedText, setEditedText] = useState('');
  const [apiResponse, setApiResponse] = useState(''); // API 응답을 저장할 상태 변수
  const [loading, setLoading] = useState(false); // 로딩 상태 관리
  const [customLoading, setCustomLoading] = useState(false); // 로딩 상태 관리
  const [myFeatures, setMyFeatures] = useState(''); // 나의 특징 상태
  const [partnerFeatures, setPartnerFeatures] = useState(''); // 상대방 특징 상태
  const [replyIntent, setReplyIntent] = useState(''); // 답장 의도 상태

  const handleBackgroundDescriptionChange = event => {
    setBackgroundDescription(event.target.value);
  };

  const handleEdit = (index, text) => {
    setEditingIndex(index);
    setEditedText(text);
  };

  const handleTextChange = event => {
    setEditedText(event.target.value);
  };

  const handleSave = index => {
    const updatedOcrText = [...ocrText];
    updatedOcrText[index].text = editedText;
    setOcrText(updatedOcrText);
    setEditingIndex(null);
  };

  const handleSubmit = async () => {
    setCustomLoading(true); // 로딩 상태 시작
    try {
      const response = await req.post(`${config.SERVER_BASE_URL}/submit`, {
        blocks: ocrText,
        backgroundDescription,
        myFeatures,
        partnerFeatures,
        replyIntent
      });
      setApiResponse(response.data.message); // API 응답 저장
      setCustomLoading(false); // 로딩 상태 종료
    } catch (error) {
      console.error('Error during submit API call', error);
      setCustomLoading(false); // 에러 발생 시 로딩 상태 종료
    }
  };

  if (customLoading) return <CustomLoadingModal />
  return (
    <Wrapper theme={theme}>
      {ocrText.length !== 0 ? (
        <Chat
          ocrText={ocrText}
          editingIndex={editingIndex}
          editedText={editedText}
          handleTextChange={handleTextChange}
          handleSave={handleSave}
          handleEdit={handleEdit}
        />
      ) : (
        <FileUpload setOcrText={setOcrText} setShowInputGroup={setShowInputGroup} setLoading={setCustomLoading} />
      )}
      {showInputGroup && (
        <AIResult
          backgroundDescription={backgroundDescription}
          handleBackgroundDescriptionChange={handleBackgroundDescriptionChange}
          myFeatures={myFeatures}
          partnerFeatures={partnerFeatures}
          replyIntent={replyIntent}
          handleSubmit={handleSubmit}
          apiResponse={apiResponse}
          setMyFeatures={setMyFeatures}
          setPartnerFeatures={setPartnerFeatures}
          setReplyIntent={setReplyIntent}
        />
      )}

      <DefaultLoadingModal open={loading} />

    </Wrapper>
  );
};
export default Main;
