import axios from 'axios';

const req = axios.create({
  // 비 로그인 상태 aixos
  timeout: 180000,
  withCredentials: false,
  headers: {
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*'
  }
});

export default req;
