import { Link } from 'react-router-dom';
import styled from 'styled-components';

const Container = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom 0;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-weight: 700;
    width:100%;
    height:100%;
`;

const Title = styled.div`
  font-size: 6rem;
  height: 120px;
`;

const Contents = styled.div`
  font-size: 2rem;
  height: 40px;
`;
const Explaination = styled.div`
  font-size: 1rem;
  height: 20px;
  font-weight: 400;
`;

const NotFound = () => (
  <Container>
    <Link to="/" style={{ color: '#000', textDecoration: 'none' }}>
      <Title>404</Title>
      <Contents>Not Found</Contents>
      <Explaination> </Explaination>
    </Link>
  </Container>
);

export default NotFound;
