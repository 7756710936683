import { styled, useTheme } from '@mui/material/styles';
import { Typography } from '@mui/material';

const Wrapper = styled('div')(({ theme }) => ({
  width: '100%',
  height: 'calc(100vh - 80px)',
  paddingTop: '80px',
  display: 'flex',
  padding: 30,
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column'
}));

const Help = () => {
  const theme = useTheme();

  return (
    <Wrapper theme={theme}>
      <Typography id="modal-modal-title" variant="h5" component="h2">
          서비스 소개
        </Typography>
          <p>
            SEART(Send + hEART)는 마음을 전하기 위해 AI를 활용하는 서비스를 제공합니다🙏
          </p>
          <p>
          'AI 답변 추천' 서비스는 메시지 답변이 어려운 상황에서 AI가 메시지를 분석하여 당신의 진심을 전달해주는 서비스 입니다.
          </p>
          <p>썸남&썸녀의 메시지, 직장 상사와의 메시지, 어려운 거절 메시지를 할때 사용해보세요!</p>
          <p>현재는 비록 베타 버전이지만 열심히 개발해서 조만간 정식 런칭 하겠습니다!😉</p>
          <p>서비스 이용 문의 및 건의 : firmcode0729@gmail.com</p>
          <p>special thanks!🙏</p>
          <p>sumi, jodev</p>
    </Wrapper>
  );
};
export default Help;
