import { cloneElement, useState } from 'react';
import { AppBar, IconButton, Toolbar, Typography, useScrollTrigger, Drawer, Box, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Divider } from '@mui/material';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MenuIcon from '@mui/icons-material/Menu';
import HelpIcon from '@mui/icons-material/Help';
import { useTheme } from '@mui/material/styles';
import HelpModal from 'components/modal/HelpModal';
import { useNavigate } from 'react-router-dom';

function ElevationScroll({ children, window }) {
  const theme = useTheme();

  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
    target: window
  });
  const darkBorder = theme.palette.mode === 'dark' ? theme.palette.dark.dark : theme.palette.grey[200];

  return cloneElement(children, {
    elevation: trigger ? 2 : 0,
    style: {
      backgroundColor: theme.palette.background.default,
      borderBottom: trigger ? 'none' : '1px solid',
      borderColor: trigger ? '' : darkBorder,
      color: theme.palette.text.dark
    }
  });
}

const AppBarContainer = () => {
  const navigate = useNavigate();
  const [drawerToggle, setDrawerToggle] = useState(false);
  const [openHelpModal, setOpenHelpModal] = useState(false);
  
  const handleShowHelp = () => {
    setOpenHelpModal(true);
  };

  const handleCloseHelpModal = () => {
    setOpenHelpModal(false);
  };
  const drawerToggler = open => event => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setDrawerToggle(open);
  };
  const refresh = () => {
    window.location.reload(true);
  };
  return (
    <>
<ElevationScroll drawerToggle={drawerToggle} drawerToggler={drawerToggler}>
        <AppBar position="static">
          <Toolbar>
            <IconButton size="large" edge="start" color="inherit" aria-label="menu" sx={{ mr: 2 }} onClick={drawerToggler(true)}>
              <MenuIcon sx={{ color: 'rgba(0, 0, 0, 0.54);' }} />
            </IconButton>
            <Typography
              variant="h6"
              component="div"
              sx={{ flexGrow: 1, textAlign: 'center', color: 'rgba(0, 0, 0, 0.74);', fontWeight: 500, cursor: 'pointer' }}
              onClick={refresh}
            >
              SEART(beta)
            </Typography>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleShowHelp}
              color="inherit"
            >
              <HelpIcon sx={{ color: 'rgba(0, 0, 0, 0.54);' }} />
            </IconButton>
          </Toolbar>
        </AppBar>
        </ElevationScroll>
        <Drawer
            anchor={'left'}
            open={drawerToggle}
            onClose={drawerToggler(false)}
          >
            <Box
                sx={{ width: 250 }}
                role="presentation"
                onClick={drawerToggler(false)}
                onKeyDown={drawerToggler(false)}
              >
                <Toolbar />
                <Divider />
                <List>
                  <ListItem disablePadding>
                      <ListItemButton onClick={() => navigate('/', { replace: true })}>
                        <ListItemIcon>
                          <InboxIcon />
                        </ListItemIcon>
                        <ListItemText primary="AI 답변 추천✌️" />
                      </ListItemButton>
                    </ListItem>
                    <ListItem disablePadding>
                      <ListItemButton onClick={() => navigate('/greenlight', { replace: true })}>
                        <ListItemIcon>
                          <InboxIcon />
                        </ListItemIcon>
                        <ListItemText primary="AI 그린라이트 분석🧐(Comming Soon!)" />
                      </ListItemButton>
                    </ListItem>
                    <ListItem disablePadding>
                    <ListItemButton onClick={() => navigate('/compatibility', { replace: true })}>
                        <ListItemIcon>
                          <InboxIcon />
                        </ListItemIcon>
                        <ListItemText primary="AI 커플 궁합 분석🥳(Comming Soon!)" />
                      </ListItemButton>
                    </ListItem>
                    <ListItem disablePadding>
                      <ListItemButton onClick={() => navigate('/help', { replace: true })}>
                        <ListItemIcon>
                          <InboxIcon />
                        </ListItemIcon>
                        <ListItemText primary="도움말" />
                      </ListItemButton>
                    </ListItem>
                    <ListItem disablePadding>
                      <ListItemButton onClick={() => navigate('/faq', { replace: true })}>
                        <ListItemIcon>
                          <InboxIcon />
                        </ListItemIcon>
                        <ListItemText primary="FAQ" />
                      </ListItemButton>
                    </ListItem>
                </List>
                <Divider />
              </Box>
          </Drawer>
      <HelpModal open={openHelpModal} handleClose={handleCloseHelpModal} />
    </>
  );
};

export default AppBarContainer;
