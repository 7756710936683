import { Routes, Route } from 'react-router-dom';
import { CssBaseline, StyledEngineProvider } from '@mui/material';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import NavigationScroll from './components/navigation/NavigationScroll';
import NotFound from './pages/NotFound';
import Main from './pages/Main';
import Greenlight from './pages/Greenlight';
import Compatibility from './pages/Compatibility';
import Help from './pages/Help';
import Faq from './pages/Faq';
import AppBar from './components/appbar/AppBar';
import ReactGA from "react-ga";

const theme = createTheme({
  fontFamily: `'Roboto', sans-serif`,
  borderRadius: 12,
  outlinedFilled: true,
  theme: 'light',
  presetColor: 'default',
  i18n: 'ko',
  rtlLayout: false
});

const gaTrackingId = "G-SLLXEX5S7M"; // 환경 변수에 저장된 추적ID 가져오기
ReactGA.initialize(gaTrackingId, { debug: true }); // react-ga 초기화 및 debug 사용
ReactGA.pageview(window.location.pathname); // 추적하려는 page 설정

const App = () => (
  <StyledEngineProvider injectFirst>
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <NavigationScroll>
        <AppBar />
        <Routes>
          <Route path="/" element={<Main />} />
          <Route path="/greenlight" element={<Greenlight />} />
          <Route path="/compatibility" element={<Compatibility />} />
          <Route path="/help" element={<Help />} />
          <Route path="/faq" element={<Faq />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </NavigationScroll>
    </ThemeProvider>
  </StyledEngineProvider>
);

export default App;
