import { useState, useRef, useEffect } from 'react'
import Box from '@mui/material/Box';
import Skeleton from '@mui/material/Skeleton';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';


const modalStyle = {
  width: '100%',
  height: '100%',
  textAlign: 'center',
  bgcolor: 'background.paper',
  p: 4,
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'center',
  flexDirection: 'column',
};

const TextLoading = [
  {
    no: 1,
    title: '대화 길이에 따라 15~30초 소요 됩니다',
    content: 'AI가 아주 열심히 분석하고 있어요🧐'
  },
  {
    no: 2,
    title: '좋은 답변 받는 꿀팁!',
    content: '핸드폰 캡쳐 기능이 아닌, 카카오톡 내부에 있는 캡쳐 기능을 사용해 주세요!\n더 많은 내용을 AI에게 입력하면 더 좋은 답변을 받을 수 있어요!\n카카오톡 내부에 있는 캡쳐 기능이 인식률이 더 높아요!'
  },
  {
    no: 3,
    title: '상대방 정보 잘 쓰는 꿀팁!',
    content: '1. 나이, 성별, MBTI 입력하기!\n2. 나와의 관계 입력하기\n(ex : 직장 동료, 소개팅 상대, 연인)'
  },
  {
    no: 4,
    title: '목적 잘 쓰는 꿀팁!',
    content: '자신의 마음을 솔직하게 적으세요\n(ex : 상대방이 정말 좋은데, 티나지 않게 답변해줘!)'
  },
  
]

const CustomLoadingModal = () => {
  
  const [loadingText, setLoadingText] = useState(TextLoading[0])
  let targetNumber = useRef(0);

  useEffect(() => {
    setInterval(() => {
      targetNumber.current = Math.floor(Math.random() * TextLoading.length);
      setLoadingText(TextLoading[targetNumber.current])
    }, 4000)
  }, [])
  
  return (
      <Box sx={modalStyle}>
        <div style={{width: '100%'}}>
          <Skeleton animation="wave" style={{width: '50%'}}/>
          <Skeleton style={{width: '30%'}}/>
        </div>
        <div  style={{padding: '32px', position: 'fixed', top: '0', width: '100%', height: '100vh', display:'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center'}}>
        <CircularProgress color="secondary" />
          <Typography variant="h5" gutterBottom fontWeight={700} style={{marginTop: "30px"}}>
          {loadingText.title}
          </Typography>
          <Typography variant="subtitle2" gutterBottom style={{color: "#808080", whiteSpace: 'pre-wrap'}}>
          {loadingText.content}
          </Typography>
        </div>
      </Box>
      
)};

export default CustomLoadingModal;
