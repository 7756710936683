import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import styled from 'styled-components';

const ResponseBox = styled.pre`
  text-overflow: ellipsis;
  white-space: pre-wrap;
`;

const AIResult = ({
  backgroundDescription,
  handleBackgroundDescriptionChange,
  myFeatures,
  partnerFeatures,
  replyIntent,
  handleSubmit,
  apiResponse,
  setMyFeatures,
  setPartnerFeatures,
  setReplyIntent
}) => {
  const refresh = () => {
    window.location.reload(true);
  };
  return (
    <>
      <Box component="div" noValidate autoComplete="off" sx={{ marginTop: 1 }}>
        <TextField
          fullWidth
          id="outlined-basic"
          label="배경 설명"
          variant="outlined"
          multiline
          maxRows={4}
          value={backgroundDescription}
          onChange={handleBackgroundDescriptionChange}
        />

        <TextField
          fullWidth
          sx={{ marginTop: 1 }}
          id="outlined-basic"
          label="답장 의도"
          multiline
          maxRows={4}
          variant="outlined"
          value={replyIntent}
          onChange={e => setReplyIntent(e.target.value)}
        />
      </Box>
      <Box
        component="div"
        sx={{
          display: 'flex',
          marginTop: 1,
          alignContent: 'space-between'
        }}
        noValidate
        autoComplete="off"
      >
        <TextField
          id="outlined-basic"
          fullWidth
          label="나의 특징"
          variant="outlined"
          value={myFeatures}
          onChange={e => setMyFeatures(e.target.value)}
        />
        <TextField
          id="outlined-basic"
          fullWidth
          label="상대방 특징"
          variant="outlined"
          value={partnerFeatures}
          onChange={e => setPartnerFeatures(e.target.value)}
        />
      </Box>
      <Button size="large" onClick={handleSubmit} variant="contained" fullWidth sx={{ marginTop: 1 }}>
        AI에게 답변 추천 받기
      </Button>
      <ResponseBox className="api-response">{apiResponse}</ResponseBox>
      {apiResponse && (
        <Button size="large" onClick={refresh} variant="contained" fullWidth sx={{ marginTop: 1 }}>
          다른 추천 받아보기
        </Button>
      )}
    </>
  );
};
export default AIResult;
