import styled from 'styled-components';
import EditIcon from '@mui/icons-material/Edit';
import IconButton from '@mui/material/IconButton';

const MsgSenderBox = styled.div`
  background-color: #ffe400;
  margin: 2px 2px 2px auto;
  padding: 10px;
  border-radius: 10px;
  color: #000000 85%;
  max-width: 60%;
`;

const MsgReceiverBox = styled.div`
  background-color: #383838;
  margin: 2px auto 2px 2px;
  padding: 10px;
  border-radius: 10px;
  color: white;
  max-width: 60%;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const MsgSenderWrapper = styled.div`
  display: flex;
  text-align: right;
`;

const MsgReceiverWrapper = styled.div`
  display: flex;
  text-align: left;
`;

const MsgSenderInput = styled.input`
  background-color: #ffe400;
  margin: 2px 2px 2px auto;
  padding: 10px;
  border-radius: 10px;
  border: 2px solid #dec701;
  color: #000000 85%;
`;

const MsgReceiverInput = styled.input`
  background-color: #383838;
  margin: 2px auto 2px 2px;
  padding: 10px;
  border-radius: 10px;
  color: white;
  border: 2px solid #000;
`;

const MsgSenderEditBox = styled.div`
  margin-left: auto;
`;

const MsgReceiverEditBox = styled.div`
  margin-right: auto;
`;

const Chat = ({ ocrText, editingIndex, editedText, handleTextChange, handleSave, handleEdit }) => (
  <Wrapper>
    {ocrText.map((block, index) =>
      block.position === 'Right' ? (
        <MsgSenderWrapper key={index}>
          {editingIndex === index ? (
            <MsgSenderEditBox>
              <IconButton size="small" onClick={() => handleSave(index)} variant="primary">
                <EditIcon sx={{ color: 'rgba(0, 0, 0, 0.54);' }} />
              </IconButton>
              <MsgSenderInput type="text" value={editedText} onChange={handleTextChange} />
            </MsgSenderEditBox>
          ) : (
            <MsgSenderBox>
              <span onClick={() => handleEdit(index, block.text)}>{block.text}</span>
            </MsgSenderBox>
          )}
        </MsgSenderWrapper>
      ) : (
        <MsgReceiverWrapper key={index}>
          {editingIndex === index ? (
            <MsgReceiverEditBox>
              <MsgReceiverInput type="text" value={editedText} onChange={handleTextChange} />
              <IconButton size="small" onClick={() => handleSave(index)} variant="primary">
                <EditIcon sx={{ color: 'rgba(0, 0, 0, 0.54);' }} />
              </IconButton>
            </MsgReceiverEditBox>
          ) : (
            <MsgReceiverBox>
              <span onClick={() => handleEdit(index, block.text)}>{block.text}</span>
            </MsgReceiverBox>
          )}
        </MsgReceiverWrapper>
      )
    )}
  </Wrapper>
);

export default Chat;
