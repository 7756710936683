import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';

const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  textAlign: 'center',
  p: 4
};

const DefaultLoadingModal = ({ open, handleClose }) => (
  <div>
    <Modal open={open} onClose={handleClose}>
      <Box sx={modalStyle}>
        <CircularProgress />
      </Box>
    </Modal>
  </div>
);

export default DefaultLoadingModal;
