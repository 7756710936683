import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import CloseIcon from '@mui/icons-material/Close';

const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4
};

const HelpModal = ({ open, handleClose }) => (
  <div>
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={modalStyle}>
        <IconButton
          size="large"
          edge="start"
          color="inherit"
          aria-label="menu"
          sx={{ right: 0, top: 0, position: 'absolute' }}
          onClick={handleClose}
        >
          <CloseIcon />
        </IconButton>
      
        <Typography id="modal-modal-title" variant="h4" component="h2">
          AI 답변 추천 서비스
        </Typography>
        <p>AI가 대화 내용, 배경, 특징을 분석하여 최고의 답변을 추천해드립니다✌️</p>

        <Typography id="modal-modal-title" variant="h6" component="h2">
        사용 방법
        </Typography>
        <Typography id="modal-modal-description" sx={{ mt: 2 }}>
          <p>1. 답변할 카카오톡 내용 캡처 - 카카오톡 내부의 캡처 기능을 사용하면 더 정확하게 인식합니다!</p>
          <p>2. 캡처한 대화 내용 업로드 및 OCR - 대화 내용을 업로드하고 수정도 가능해요!</p>
          <p>3. 상황, 특징, 의도를 입력 - 자세하게 설명할수록 더 정확하고 높은 답변을 줍니다.</p>
          <p>4. AI에게 답변 추천 받기 - 10초 ~ 20초 정도 기다려주세😊</p>
        </Typography>
      </Box>
    </Modal>
  </div>
);

export default HelpModal;
