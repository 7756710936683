import { useState } from 'react';
import req from 'lib/axios';
import config from 'configs';
import { styled } from '@mui/material/styles';
import { Button, TextField, Typography } from '@mui/material';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1
});

const FileUpload = ({ setOcrText, setShowInputGroup, setLoading }) => {
  const [partnerName, setPartnerName] = useState(''); // 상대방 이름 상태
  const [selectedFile, setSelectedFile] = useState(null);
  const [error, setError] = useState(null);

  const handlePartnerNameChange = event => {
    setPartnerName(event.target.value);
    if (event.target.value.length > 0) {
      setError(null);
    } else {
      setError('상대방 이름을 입력해주세요.');
    }
  };

  const handleFileChange = event => {
    setSelectedFile(event.target.files[0]);
  };

  const handleUpload = async () => {
    if (partnerName.length === 0) {
      setError('상대방 이름을 입력해주세요.');
    } else {
      setLoading(true);
      const formData = new FormData();
      formData.append('file', selectedFile);
      formData.append('partnerName', partnerName); // 상대방 이름 추가

      try {
        const response = await req.post(`${config.SERVER_BASE_URL}/ocr`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        });
        setOcrText(response.data); // 응답 데이터 구조에 따라 변경
        setShowInputGroup(true); // 파일 업로드 시 입력 그룹 표시
        setLoading(false);
      } catch (error) {
        console.error('Error during API call', error);
        setLoading(false);
      }
    }
  };

  return (
    <>
      <Typography id="modal-modal-title" variant="h6" component="h2" sx={{ textAlign: 'center' }}>
        <iframe
          width="315"
          height="560"
          src="https://www.youtube.com/embed/YTqKuKKgHQk"
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen>
        </iframe>
      </Typography>
      <Typography id="modal-modal-title" variant="h6" component="h2" sx={{ textAlign: 'center' }}>
        카카오톡 대화를 캡처해서 파일을 업로드 해주세요.
      </Typography>
      <Button component="label" fullWidth variant="outlined" startIcon={<CloudUploadIcon />}>
        업로드할 파일을 선택해 주세요
        <VisuallyHiddenInput type="file" onChange={handleFileChange} />
      </Button>
      {selectedFile && <Typography>선택한 파일 : {selectedFile.name}</Typography>}
      <TextField
        size="small"
        fullWidth
        required
        error={error}
        helperText={error}
        id="partnerName"
        placeholder="상대방 이름을 입력해주세요."
        value={partnerName}
        onChange={handlePartnerNameChange}
        sx={{ marginTop: 1 }}
      />
      <Button size="large" onClick={handleUpload} variant="contained" fullWidth sx={{ marginTop: 1 }}>
        대화 내용 업로드
      </Button>
    </>
  );
};

export default FileUpload;
