import { styled, useTheme } from '@mui/material/styles';
import ConstructionIcon from '@mui/icons-material/Construction';
import { Typography } from '@mui/material';

const Wrapper = styled('div')(({ theme }) => ({
  width: '100%',
  height: 'calc(100vh - 80px)',
  paddingTop: '80px',
  display: 'flex',
  padding: 30,
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column'
}));

const Compatibility = () => {
  const theme = useTheme();

  return (
    <Wrapper theme={theme}>
      <ConstructionIcon sx={{fontSize:"100px"}} />
      <p></p>
      <Typography id="modal-modal-title" variant="h5" component="h2">
          AI 커플 궁합 분석🥳
        </Typography>
        <p>커플의 메시지 내용을 분석해서 궁합을 분석하고 더 잘 맞는 연애를 위해 어떤 노력을 해야할지 알려드립니다!</p>
      
        <p></p>
        <p></p>
      <Typography variant="h5" gutterBottom fontWeight={700} style={{marginTop: "20px"}}>
        열심히 개발중 입니당...😂
      </Typography>
      <p>🫠😭🥲</p>
    </Wrapper>
  );
};
export default Compatibility;
