import { styled, useTheme } from '@mui/material/styles';
import ManageHistory from '@mui/icons-material/ManageHistory';
import { Typography } from '@mui/material';

const Wrapper = styled('div')(({ theme }) => ({
  width: '100%',
  height: 'calc(100vh - 80px)',
  paddingTop: '80px',
  display: 'flex',
  padding: 30,
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column'
}));

const Faq = () => {
  const theme = useTheme();

  return (
    <Wrapper theme={theme}>
      <ManageHistory sx={{fontSize:"64px"}} />
      <Typography variant="h5" gutterBottom fontWeight={700} style={{marginTop: "20px"}}>
        Comming Soon...
      </Typography>
    </Wrapper>
  );
};
export default Faq;
